import { useContext } from 'react';

import { UserContext } from '../../../common/authentication/UserContext';
import { User } from '../../../common/authentication/types';
import { Filter } from '../../../common/consultation/types';
import { InboxPreset } from '../../graphql/types';
import { ConsultationInbox } from '../components';

interface Props {
  location?: {
    state: {
      forceReload: boolean;
    };
  };
}

const DEFAULT_FILTER: Filter = {
  statuses: ['new', 'in_progress', 'reminded'],
  sort_by: 'urgency',
  query: '',
  inboxPreset: InboxPreset.New,
};

export function InboxPage(props: Props) {
  const { user } = useContext(UserContext);
  const { location } = props;
  const forceReload = location?.state?.forceReload || false;

  return user && <ConsultationInbox user={user as User} forceReload={forceReload} defaultFilter={DEFAULT_FILTER} />;
}
